import { Effect, createEffect } from 'effector-root'
import { http } from '@api/http'

export interface ILiveVideoDto {
  id: number
  url: string
  title: string
  description: string
  created_at: string
}

export interface ILiveVideoRepository {
  findAllFx: Effect<void, ILiveVideoDto[], Error>
}

export const liveVideoRepository: ILiveVideoRepository = {
  findAllFx: createEffect(
    (): Promise<ILiveVideoDto[]> => http.get('/live-videos?_sort=created_at:DESC').then((res) => res.data),
  ),
}
