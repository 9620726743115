import { createEffect, Effect } from 'effector-root'
import { http } from '@api/http'
import { IFileMediaDto } from '@api/typings'

export interface IContactsRepository {
  findFx: Effect<void, IContactsDto, Error>
  subscribeFx: Effect<ISubscribeDto, void, Error>
}

export interface IContactsDto {
  id: number
  file: IFileMediaDto
  phone: string
  email: string
}

export interface ISubscribeDto {
  email: string
}

export const contactsRepository: IContactsRepository = {
  findFx: createEffect((): Promise<IContactsDto> => http.get('/contacts').then((res) => res.data)),
  subscribeFx: createEffect((dto: ISubscribeDto): Promise<void> => http.post('/subscribers', dto)),
}
