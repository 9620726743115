import React from 'react'
import { reflect } from '@effector/reflect/ssr'
import * as model from '@features/music-player/model'
import { ITrack } from '@features/music-player/model'
import { DownloadIcon } from '@ui/icons/download'
import { ExpandedMobilePlayer } from '@features/music-player/bottom-player/expanded-mobile-player'
import { Modal } from '@ui/modal'
import { $isMobile } from '@features/window-size-observer'
import Play from './play.svg'
import Pause from './pause.svg'
import Prev from './prev.svg'
import Next from './next.svg'
import Close from './close.svg'
import Close2 from './close2.svg'
import styles from './bottom-player.module.scss'
import styled from 'styled-components'
import { Devices } from '@styles'

interface PlayerProps {
  isPlaying: boolean
  track: ITrack | null
  playedPercents: number
  inDrag: boolean
  isMobile: boolean
  playClicked: (id: React.ReactText) => void
  pauseClicked: (id: React.ReactText) => void
  nextTrackClicked: () => void
  prevTrackClicked: () => void
  closeClicked: () => void
  progressBarClicked: ({ progressPercents }: { progressPercents: number }) => void
  mouseMoved: ({ progressPercents }: { progressPercents: number }) => void
  dragStateChanged: (inDrag: boolean) => void
}

const Player = (props: PlayerProps) => {
  const [mobileTrackModalIsOpened, setMobileTrackModalIsOpened] = React.useState(false)
  const thumbRef = React.useRef<HTMLDivElement>(null)
  const progressBarRef = React.useRef<HTMLDivElement>(null)

  const closeMobileModal = () => setMobileTrackModalIsOpened(false)

  const openMobileModal = () => {
    if (props.isMobile) {
      setMobileTrackModalIsOpened(true)
    }
  }

  const onMouseUp = React.useCallback(
    (e: MouseEvent) => {
      props.mouseMoved({ progressPercents: (e.clientX / window.innerWidth) * 100 })
      props.dragStateChanged(false)
    },
    [props.dragStateChanged, props.mouseMoved],
  )

  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!props.inDrag) {
        return
      }

      if (thumbRef.current !== null) {
        thumbRef.current.style.left = `${e.clientX}px`
      }

      if (progressBarRef.current !== null) {
        progressBarRef.current.style.width = `${e.clientX}px`
      }
    },
    [props.inDrag],
  )

  React.useEffect(() => {
    document.addEventListener('mouseup', onMouseUp)

    return () => document.removeEventListener('mouseup', onMouseUp)
  }, [onMouseUp])

  React.useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => document.removeEventListener('mousemove', onMouseMove)
  }, [onMouseMove])

  if (props.track === null) {
    return null
  }

  const progressBarWidth = `${(props.playedPercents / 100) * window.innerWidth}px`

  const onProgressBarClick = (e: React.MouseEvent) =>
    props.progressBarClicked({ progressPercents: (e.clientX / window.innerWidth) * 100 })

  const onMouseDown = (_: React.MouseEvent) => props.dragStateChanged(true)

  return (
    <>
      <Modal hideOverlay isOpened={mobileTrackModalIsOpened} onClose={closeMobileModal}>
        <ExpandedMobilePlayer
          track={props.track}
          isPlaying={props.isPlaying}
          playedPercents={props.playedPercents}
          onClose={closeMobileModal}
          progressBarClicked={props.progressBarClicked}
          onPlayClick={() => props.playClicked(props.track!.id)}
          onPauseClick={() => props.pauseClicked(props.track!.id)}
          onNextClick={() => props.nextTrackClicked()}
          onPrevClick={() => props.prevTrackClicked()}
        />
      </Modal>
      <div className={styles.player} onClick={openMobileModal}>
        <div className={styles.progressBarLayout} onClick={onProgressBarClick} onMouseDown={onMouseDown}>
          <div className={styles.progressBar} style={{ width: progressBarWidth }} ref={progressBarRef} />
          <div className={styles.thumb} style={{ left: progressBarWidth }} ref={thumbRef} />
        </div>

        <div className={styles.controls}>
          <ButtonWrapper onClick={props.prevTrackClicked}>
            <Prev />
          </ButtonWrapper>
          {props.isPlaying ? (
            <ButtonWrapper
              onClick={(e) => {
                e.stopPropagation()
                props.pauseClicked(props.track!.id)
              }}
            >
              <Pause />
            </ButtonWrapper>
          ) : (
            <ButtonWrapper
              onClick={(e) => {
                e.stopPropagation()
                props.playClicked(props.track!.id)
              }}
            >
              <Play />
            </ButtonWrapper>
          )}
          <ButtonWrapper onClick={props.nextTrackClicked}>
            <Next />
          </ButtonWrapper>
        </div>

        <div className={styles.trackInfoWrapper}>
          <MobileCloseButtonWrapper
            onClick={(e) => {
              e.stopPropagation()
              props.closeClicked()
            }}
          >
            <Close2 />
          </MobileCloseButtonWrapper>

          <img className={styles.cover} src={props.track.coverUrl} alt='' />

          <div className={styles.trackInfo}>
            <span className={styles.title}>{props.track.title}</span>
            <span className={styles.artist}>{props.track.artist}</span>
          </div>
        </div>

        <div className={styles.actions}>
          <a className={styles.downloadButtonWrapper} href={props.track.downloadLink} target='_blank'>
            <div>
              <DownloadIcon />
            </div>
            <span>Скачать</span>
          </a>

          <div className={styles.closeButtonWrapper} onClick={props.closeClicked}>
            <Close />
          </div>
        </div>
      </div>
    </>
  )
}

const MobileCloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 25px;
  top: 25px;

  @media screen and ${Devices.laptop} {
    display: none;
  }
`

type ButtonWrapperProps = React.HTMLAttributes<HTMLElement>

const ButtonWrapper = (props: ButtonWrapperProps) => (
  <div className={styles.buttonWrapper} onClick={props.onClick}>
    {props.children}
  </div>
)

export const BottomPlayer = reflect({
  view: Player,
  bind: {
    track: model.$selectedTrack,
    isPlaying: model.$isPlaying,
    inDrag: model.$inDrag,
    isMobile: $isMobile,
    dragStateChanged: model.dragStateChanged,
    mouseMoved: model.mouseMoved,
    progressBarClicked: model.progressBarClicked,
    playClicked: model.playClicked,
    pauseClicked: model.pauseClicked,
    playedPercents: model.$playedPercents,
    nextTrackClicked: model.nextTrackClicked,
    prevTrackClicked: model.prevTrackClicked,
    closeClicked: model.closeClicked,
  },
})
