import { createEffect, Effect } from 'effector-root'
import { http } from '@api/http'
import { IAudioMedia, IImageMediaDto } from '@api/typings'

export interface ITracksRepository {
  findAllFx: Effect<void, ITrackDto[], Error>
}

export interface ITrackDto {
  artist: string
  backgroundCover: IImageMediaDto
  created_at: string
  description: string
  id: number
  published_at: string
  title: string
  track: IAudioMedia
  trackCover: IImageMediaDto
  trackTitle: IImageMediaDto
  updated_at: string
  year: number
  downloadLink: string
}

export const tracksRepository: ITracksRepository = {
  findAllFx: createEffect(
    (): Promise<ITrackDto[]> => http.get('/tracks?_sort=published_at:DESC').then((res) => res.data),
  ),
}
