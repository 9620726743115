import styled from 'styled-components'
import CircleIcon from './circle.svg'

type Color = 'black' | 'pink'

const cirlceColors: { [key in Color]: string } = {
  black: '#000000',
  pink: '#E469D1',
}

interface CircleProps {
  color?: Color
}

export const Circle = styled(CircleIcon)<CircleProps>`
  fill: ${(props) => (props.color === undefined ? cirlceColors.black : cirlceColors[props.color])};

  circle {
    fill: ${(props) => (props.color === undefined ? cirlceColors.black : cirlceColors[props.color])};
  }
`
