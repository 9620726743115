import React from 'react'
import { useEvent, useGate, useStore } from 'effector-react/ssr'
import * as model from '@features/music-player/model'

export const AudioSource = () => {
  const ref = React.useRef<HTMLAudioElement>(null)
  useGate(model.audioSourceGate, ref)

  const track = useStore(model.$selectedTrack)
  const playedPercentsChanged = useEvent(model.playedPercentsChanged)

  const timeUpdated = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    const target = e.target as HTMLAudioElement
    const currentTime = target.currentTime
    const trackDuration = ref.current?.duration ?? 0
    const playedPercents = (currentTime / trackDuration) * 100

    playedPercentsChanged(playedPercents)
  }

  return <audio src={track?.sourceUrl} ref={ref} onTimeUpdate={timeUpdated} />
}
