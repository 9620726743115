import { createEffect, Effect } from 'effector-root'
import { http } from '@api/http'
import { IImageMediaDto } from '@api/typings'

export interface INewsRepository {
  findAllFx: Effect<void, INewsDto[], Error>
  findByIdFx: Effect<string, INewsDto, Error>
}

export interface INewsDto {
  id: number
  preview: IImageMediaDto
  date: string
  title: string
  description: string
  fullDescription: string
  content?: IImageMediaDto
}

export const newsRepository: INewsRepository = {
  findAllFx: createEffect((): Promise<INewsDto[]> => http.get('/news-items?_sort=date:DESC').then((res) => res.data)),
  findByIdFx: createEffect((id: string): Promise<INewsDto> => http.get(`/news-items/${id}`).then((res) => res.data)),
}
