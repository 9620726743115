export enum Devices {
  mobileS = '(min-width: 320px)',
  mobileLandscapeS = '(min-width: 568px)',
  mobileM = '(min-width: 375px)',
  mobileLandscapeM = '(min-width: 667px)',
  mobileL = '(min-width: 425px)',
  mobileLandscapeL = '(min-width: 714px)',
  tablet = '(min-width: 768px)',
  laptop = '(min-width: 1200px)',
  desktopS = '(min-width: 1366px)',
  desktopM = '(min-width: 1920px)',
  desktopL = '(min-width: 2560px)',
  unknown = 'unknown',
}
