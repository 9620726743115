import { combine, createEvent, createStore, forward, guard } from 'effector-root'
import { always, dec, inc, not } from 'ramda'
import { createStart } from '@lib/page-routing'
import { ISliderItemDto, LogoColors, SliderActions, sliderItemsRepository } from '@api/sliders'
import HomePageCover from '@assets/images/home-page-cover.jpg'
import { metaTagsRepository } from '@api/meta-tags'
import { isClientSide, isServerSide } from '@lib/ssr'
import { homePageVideoRepository, IHomePageVideoDto } from '@api/home-page-video'

export const pageLoaded = createStart()

export const nextClicked = createEvent()

const setNextItem = createEvent()

export const prevClicked = createEvent()

const setPrevItem = createEvent()

export const resetVideo = createEvent()

export const $homePageMetaTags = createStore('')

const $dataWasFetchedServerSide = createStore(false)

const homePageFallback = { ext: '', hash: '', height: 0, width: 0, name: '', mime: '', size: 0, url: HomePageCover }

export const $video = createStore<{ id: number; url: string; mime: string } | null>(null)

export const $backgroundDesktopVideo = createStore<{ id: number; url: string; mime: string } | null>(null)

$video.on(homePageVideoRepository.findVideoFx.doneData, (_, { videoSource }) => videoSource).reset(resetVideo)

$backgroundDesktopVideo.on(
  homePageVideoRepository.findBackgroundDesktopVideoFx.doneData,
  (_, { videoSource }) => videoSource,
)

const $sliderItems = createStore<ISliderItemDto[]>([
  {
    created_at: '',
    id: 1,
    published_at: '',
    updated_at: '',
    action: SliderActions.Play,
    logocolor: LogoColors.White,
    cover: {
      url: HomePageCover,
      alternativeText: '',
      caption: '',
      created_at: '',
      ext: '',
      hash: '',
      height: 0,
      width: 0,
      id: 1,
      mime: '',
      name: '',
      size: 0,
      updated_at: '',
      formats: {
        mobileS: homePageFallback,
        mobileL: homePageFallback,
        mobileM: homePageFallback,
        desktopS: homePageFallback,
        desktopM: homePageFallback,
        tablet: homePageFallback,
        thumbnail: homePageFallback,
      },
    },
  },
])

export const $currentItemIndex = createStore(0)

export const $itemsCount = $sliderItems.map((items) => items.length)

export const $currentItem = combine($currentItemIndex, $sliderItems, selectItemOrReturnNull)

$homePageMetaTags.on(metaTagsRepository.findHomePageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

$dataWasFetchedServerSide.on(metaTagsRepository.findHomePageMetaTagsFx.doneData, always(true))

$sliderItems.on(sliderItemsRepository.findAllFx.doneData, (_, items) => items)

$currentItemIndex.on(setPrevItem, dec).on(setNextItem, inc)

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: [
    metaTagsRepository.findHomePageMetaTagsFx,
    homePageVideoRepository.findVideoFx,
    homePageVideoRepository.findBackgroundDesktopVideoFx,
  ],
})

guard({
  source: $currentItemIndex,
  clock: prevClicked,
  filter: (index) => index !== 0,
  target: setPrevItem,
})

guard({
  source: [$currentItemIndex, $sliderItems],
  clock: nextClicked,
  filter: ([currentIndex, items]) => currentIndex !== items.length - 1,
  target: setNextItem,
})

function selectItemOrReturnNull<T>(index: number, items: Array<T>): T | null {
  const item = items[index]

  if (item === undefined) {
    return null
  }

  return item
}
