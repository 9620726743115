import { Devices } from '@styles'
import { Event } from 'effector'
import { StartParams, useStart, withStart } from '@lib/page-routing'
import { LoadableComponent } from '@loadable/component'
import * as React from 'react'

export const isServerSide = () => typeof window === 'undefined'

export const isClientSide = () => typeof window !== 'undefined'

export const isMobile = (device: Devices) => [Devices.mobileS, Devices.mobileM, Devices.mobileL].includes(device)

export const withModel = (model: { pageLoaded: Event<StartParams> }, component: LoadableComponent<any>) =>
  withStart(model.pageLoaded, () => {
    useStart(model.pageLoaded)

    const Cumponent = component

    return (
      <>
        <Cumponent />
      </>
    )
  })
