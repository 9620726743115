import * as React from 'react'
import {} from 'styled-components/cssprop'
import { createGlobalStyle } from 'styled-components'
import { Scope } from 'effector/fork'
import { Provider } from 'effector-react/ssr'
import { Pages } from '@pages'
import { BottomPlayer } from '@features/music-player/bottom-player'
import { AudioSource } from '@features/music-player/audio-source'
import { PageCover } from '@features/page-cover'
import { WindowSizeObserver } from '@features/window-size-observer'

interface Props {
  root: Scope
}

const Globals = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }

  * {
    box-sizing: border-box;
  }
`

export const Application: React.FC<Props> = ({ root }) => (
  <Provider value={root}>
    <WindowSizeObserver />
    <AudioSource />
    <PageCover />
    <Globals />
    <Pages />
    <BottomPlayer />
  </Provider>
)
