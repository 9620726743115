import { combine, createEvent, createStore, guard, forward } from 'effector-root'
import { always, not } from 'ramda'
import { createStart } from '@lib/page-routing'
import { liveVideoRepository } from '@api/live-videos'
import { isClientSide } from '@lib/ssr'
import { metaTagsRepository } from '@api/meta-tags'

export interface LiveVideo {
  id: number
  url: string
  title: string
  description: string
  date: Date
}

export const pageLoaded = createStart()

export const selectedVideoChanged = createEvent<number>()

export const resetSelectedVideo = createEvent()

const $dataWasFetchedServerSide = createStore(false)

export const $livePageMetaTags = createStore('')

export const $videos = createStore<LiveVideo[]>([])

export const $selectedVideoId = createStore<number | null>(null)

export const $selectedVideo = combine($videos, $selectedVideoId, (videos, selectedVideoId) => {
  const video = videos.find((video) => video.id === selectedVideoId)

  return video === undefined
    ? null
    : {
        id: video.id,
        title: video.title,
        description: video.description,
        date: video.date,
        type: 'video/youtube',
        src: video.url,
      }
})

$dataWasFetchedServerSide.on(metaTagsRepository.findLivePageMetaTagsFx.doneData, always(true))

$livePageMetaTags.on(metaTagsRepository.findLivePageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

$selectedVideoId
  .on(selectedVideoChanged, (_, id) => id)
  .on(liveVideoRepository.findAllFx.doneData, (_, videos) => {
    return videos[0] === undefined ? null : videos[0].id
  })
  .reset(resetSelectedVideo)

forward({
  from: liveVideoRepository.findAllFx.doneData.map((videos) =>
    videos.map((v) => ({ ...v, date: new Date(v.created_at) })),
  ),
  to: $videos,
})

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: metaTagsRepository.findLivePageMetaTagsFx,
})

guard({
  clock: pageLoaded,
  filter: isClientSide,
  target: liveVideoRepository.findAllFx,
})
