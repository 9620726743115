import { Effect, createEffect } from 'effector-root'
import { http } from '@api/http'

export interface IHomePageVideoDto {
  id: number
  videoSource: { id: number; url: string; mime: string }
}

export interface IHomePageVideoRepository {
  findVideoFx: Effect<void, IHomePageVideoDto, Error>
  findBackgroundDesktopVideoFx: Effect<void, IHomePageVideoDto, Error>
}

export const homePageVideoRepository: IHomePageVideoRepository = {
  findVideoFx: createEffect((): Promise<IHomePageVideoDto> => http.get('/home-page-video').then((res) => res.data)),
  findBackgroundDesktopVideoFx: createEffect(
    (): Promise<IHomePageVideoDto> => http.get('/home-page-background-video').then((res) => res.data),
  ),
}
