import { combine, createEffect, createEvent, createStore, guard, sample } from 'effector-root'
import { http } from '@api/http'
import { IImageMediaDto } from '@api/typings'
import { createStart } from '@lib/page-routing'

export const pageLoaded = createStart()

type Photo = { id: number; photo: IImageMediaDto }

const getPhotosFx = createEffect(
  (album: string): Promise<Photo[]> => {
    return http.get(`/photo-galleries-${album}`).then((res) => res.data)
  },
)

export const selectPhoto = createEvent<number>()

export const next = createEvent()

export const prev = createEvent()

export const resetSelectedPhoto = createEvent()

export const $photos = createStore<Photo[]>([])

const $selectedPhotoIndex = createStore<number | null>(null)

export const $selectedPhoto = combine([$photos, $selectedPhotoIndex], ([photos, selectedPhotoIndex]) => {
  if (selectedPhotoIndex === null) {
    return null
  }

  return photos[selectedPhotoIndex]
})

$photos.on(getPhotosFx.doneData, (_, photos) => photos).reset(resetSelectedPhoto)

$selectedPhotoIndex.on(selectPhoto, (_, index) => index).reset(resetSelectedPhoto)

sample({
  clock: pageLoaded,
  target: getPhotosFx,
  fn: ({ query }) => {
    if (!query || !query.album) {
      return '1'
    }

    return query.album
  },
})

sample({
  clock: getPhotosFx.doneData,
  target: selectPhoto.prepend((photos: Photo[]) => {
    const first = photos[0]

    if (!first) {
      return -1
    }

    return 0
  }),
})

guard({
  source: sample({
    source: { photos: $photos, selectedPhotoIndex: $selectedPhotoIndex },
    clock: next,
  }),
  filter: (params: {
    photos: Photo[]
    selectedPhotoIndex: number | null
  }): params is { photos: Photo[]; selectedPhotoIndex: number } => {
    if (params.selectedPhotoIndex === null) {
      return false
    }

    return params.selectedPhotoIndex + 1 <= params.photos.length - 1
  },
  target: selectPhoto.prepend(({ selectedPhotoIndex }: { selectedPhotoIndex: number }) => selectedPhotoIndex + 1),
})

guard({
  source: sample({
    source: { photos: $photos, selectedPhotoIndex: $selectedPhotoIndex },
    clock: prev,
  }),
  filter: (params: {
    photos: Photo[]
    selectedPhotoIndex: number | null
  }): params is { photos: Photo[]; selectedPhotoIndex: number } => {
    if (params.selectedPhotoIndex === null) {
      return false
    }

    return params.selectedPhotoIndex - 1 >= 0
  },
  target: selectPhoto.prepend(({ selectedPhotoIndex }: { selectedPhotoIndex: number }) => selectedPhotoIndex - 1),
})
