import React from 'react'
import { isClientSide } from '@lib/ssr'
import styles from './disabled-scroll.module.scss'

export function useDisabledBodyScroll(initialState: boolean): [boolean, (state: boolean) => void] {
  const [scrollIsDisabled, setScrollIsDisabled] = React.useState(initialState)
  const scrollState = React.useRef<{ left: number; top: number }>({ left: 0, top: 0 })

  const onChange = React.useCallback((state: boolean) => {
    if (!isClientSide()) {
      return
    }

    if (state) {
      scrollState.current = { left: document.documentElement.scrollLeft, top: document.documentElement.scrollTop }
    }

    setScrollIsDisabled(state)
  }, [])

  React.useEffect(() => {
    if (!isClientSide()) {
      return
    }

    if (scrollIsDisabled) {
      document.documentElement.classList.add(styles.disabledScroll)
      document.documentElement.style.top = `-${scrollState.current.top}px`
      return
    }

    document.documentElement.style.top = ''
    document.documentElement.classList.remove(styles.disabledScroll)
    document.documentElement.scrollTo(scrollState.current)
  }, [scrollIsDisabled])

  return [scrollIsDisabled, onChange]
}
