export const paths = {
  home: () => '/',
  about: () => '/about',
  aboutOld: () => '/about_old',
  music: () => '/music',
  photoVideo: () => '/photo-video',
  photo: () => '/photo-video/photo',
  photoGallery: () => '/photo-video/photo/gallery',
  videoGallery: () => '/photo-video/video/gallery',
  news: () => '/news',
  newsById: (id = ':id') => `/news/${id}`,
  live: () => '/live-videos',
  store: () => '/store',
  contacts: () => '/contacts',
}
