import React from 'react'
import { combine, createEvent, createStore, forward, guard, root, sample } from 'effector-root'
import { always, compose, equals, find, isNil, not, propEq } from 'ramda'
import { createGate } from 'effector-react/ssr'
import { throttle } from 'patronum/throttle'
import { EntityId } from '@typings'
import { ITrackDto } from '@api/tracks'

export interface ITrack {
  id: EntityId
  coverUrl: string
  sourceUrl: string
  trackTitleUrl: string
  year: number
  artist: string
  title: string
  description: string
  downloadLink: string
}

export const trackModalOpened = createEvent()

export const trackModalClosed = createEvent()

export const playClicked = createEvent<EntityId>()

export const trackCoverClicked = createEvent<EntityId>()

export const pauseClicked = createEvent<EntityId>()

export const prevTrackClicked = createEvent()

export const progressBarClicked = createEvent<{ progressPercents: number }>()

export const mouseMoved = createEvent<{ progressPercents: number }>()

export const dragStateChanged = createEvent<boolean>()

export const nextTrackClicked = createEvent()

export const audioSourceGate = createGate<React.RefObject<HTMLAudioElement>>({ domain: root })

export const playedPercentsChanged = createEvent<number>()

export const trackEnded = createEvent()

export const closeClicked = createEvent()

const throttledPlayedPercentsChanged = throttle({ source: playedPercentsChanged, timeout: 10 })

const $playerRef = createStore<React.RefObject<HTMLAudioElement> | null>(null)

export const $selectedTrackId = createStore<EntityId | null>(null)

export const $openedTrackId = createStore<EntityId | null>(null)

export const $playingTrackId = createStore<EntityId | null>(null)

export const $tracks = createStore<ITrack[]>([])

export const $trackModalIsOpened = createStore(false)

export const $playedPercents = createStore(0)

export const $inDrag = createStore(false)

export const $isPlaying = $playingTrackId.map(compose(not, isNil))

export const $selectedTrack = combine(
  [$selectedTrackId, $tracks],
  ([trackId, tracks]) => find(propEq('id', trackId), tracks) ?? null,
)

export const $openedTrack = combine(
  [$openedTrackId, $tracks],
  ([trackId, tracks]) => find(propEq('id', trackId), tracks) ?? null,
)

export const $playingTrack = combine(
  [$playingTrackId, $tracks],
  ([trackId, tracks]) => find(propEq('id', trackId), tracks) ?? null,
)

const $prevTrack = combine($playingTrackId, $tracks, (playingTrackId, tracks) => {
  const currentTrackIndex = tracks.findIndex((track) => track.id === playingTrackId)

  if (currentTrackIndex === -1) {
    return null
  }

  const prevTrack = tracks[currentTrackIndex - 1]

  return prevTrack ?? null
})

const $nextTrack = combine($playingTrackId, $tracks, (playingTrackId, tracks) => {
  const currentTrackIndex = tracks.findIndex((track) => track.id === playingTrackId)

  if (currentTrackIndex === -1) {
    return null
  }

  const nextTrack = tracks[currentTrackIndex + 1]

  return nextTrack ?? null
})

$playerRef.on(audioSourceGate.open, (_, ref) => ref).reset(audioSourceGate.close)

$trackModalIsOpened.on(trackModalOpened, always(true)).reset(trackModalClosed)

$selectedTrackId.on(playClicked, (_, trackId) => trackId).reset(closeClicked)

$playingTrackId.on(playClicked, (_, trackId) => trackId).reset(pauseClicked, closeClicked)

$openedTrackId.on(trackCoverClicked, (_, trackId) => trackId)

$inDrag.on(dragStateChanged, (_, inDrag) => inDrag)

forward({ from: trackCoverClicked, to: trackModalOpened })

guard({
  source: throttledPlayedPercentsChanged,
  filter: $inDrag.map(not),
  target: $playedPercents,
})

guard({
  source: $nextTrack,
  filter: (track): track is ITrack => track !== null,
  clock: [nextTrackClicked, trackEnded],
  target: playClicked.prepend((track: ITrack) => track.id),
})

guard({
  source: $prevTrack,
  filter: (track): track is ITrack => track !== null,
  clock: prevTrackClicked,
  target: playClicked.prepend((track: ITrack) => track.id),
})

guard({
  source: $playedPercents,
  filter: equals(100),
  target: trackEnded,
})

const playInPlayer = guard({
  source: $playerRef,
  clock: playClicked,
  filter: (ref): ref is React.RefObject<HTMLAudioElement> => ref !== null,
})

const pauseInPlayer = guard({
  source: $playerRef,
  clock: [pauseClicked, closeClicked],
  filter: (ref): ref is React.RefObject<HTMLAudioElement> => ref !== null,
})

const progressBarMoved = guard({
  source: sample({
    source: $inDrag,
    clock: mouseMoved,
    fn: (inDrag, { progressPercents }) => ({ inDrag, progressPercents }),
  }),
  filter: (source) => source.inDrag,
}).map((source) => ({ progressPercents: source.progressPercents }))

const changeCurrentTime = sample({
  source: guard({
    source: $playerRef,
    filter: (ref): ref is React.RefObject<HTMLAudioElement> => ref !== null,
  }),
  clock: [progressBarClicked, progressBarMoved],
  fn: (ref, { progressPercents }) => ({ ref, progressPercents }),
})

playInPlayer.watch((ref) => {
  Promise.resolve().then(() => ref.current?.play())
})

pauseInPlayer.watch((ref) => {
  Promise.resolve().then(() => ref.current?.pause())
})

changeCurrentTime.watch(({ ref, progressPercents }) => {
  Promise.resolve().then(() => {
    if (ref.current !== null) {
      ref.current.currentTime = (progressPercents * ref.current.duration) / 100
    }
  })
})

export const toTrack = (dto: ITrackDto): ITrack => ({
  id: dto.id,
  description: dto.description,
  artist: dto.artist,
  coverUrl: dto.trackCover.url,
  sourceUrl: dto.track.url,
  title: dto.title,
  year: dto.year,
  downloadLink: dto.downloadLink,
  trackTitleUrl: dto.trackTitle.url,
})
