import { RouteConfig } from 'react-router-config'
import loadable from '@loadable/component'
import * as homePageModel from '@pages/home/model'
import * as musicPageModel from '@pages/music/model'
import * as aboutPageModel from '@pages/about/model'
import * as photoVideoModel from '@pages/photo-video/model'
import * as contactsPageModel from '@pages/contacts/model'
import * as livePageModel from '@pages/live/model'
import * as newsPageModel from '@pages/news/model'
import * as singleNewsModel from '@pages/news/single-news/model'
import * as photoGalleryModel from '@pages/photo-gallery/model'
import * as videoGalleryModel from '@pages/video-gallery/model'
import { Error404Page } from '@pages/error404'
import { withModel } from '@lib/ssr'
import { paths } from './paths'

export const ROUTES: RouteConfig[] = [
  {
    path: paths.home(),
    exact: true,
    component: withModel(
      homePageModel,
      loadable(() => import('@pages/home'), {
        ssr: true,
        resolveComponent: (component) => component.HomePage,
      }),
    ),
  },
  {
    path: paths.about(),
    exact: true,
    component: withModel(
      aboutPageModel,
      loadable(() => import('@pages/about'), {
        ssr: true,
        resolveComponent: (component) => component.AboutPage,
      }),
    ),
  },
  {
    path: paths.music(),
    exact: true,
    component: withModel(
      musicPageModel,
      loadable(() => import('@pages/music'), {
        ssr: true,
        resolveComponent: (component) => component.MusicPage,
      }),
    ),
  },
  {
    path: paths.photoVideo(),
    exact: true,
    component: withModel(
      photoVideoModel,
      loadable(() => import('@pages/photo-video'), {
        ssr: true,
        resolveComponent: (component) => component.PhotoVideoPage,
      }),
    ),
  },
  {
    path: paths.photo(),
    exact: true,
    component: loadable(() => import('@pages/photo'), {
      ssr: true,
      resolveComponent: (component) => component.PhotoPage,
    }),
  },
  {
    path: paths.photoGallery(),
    exact: true,
    component: withModel(
      photoGalleryModel,
      loadable(() => import('@pages/photo-gallery'), {
        ssr: true,
        resolveComponent: (component) => component.PhotoGalleryPage,
      }),
    ),
  },
  {
    path: paths.videoGallery(),
    exact: true,
    component: withModel(
      videoGalleryModel,
      loadable(() => import('@pages/video-gallery'), {
        ssr: true,
        resolveComponent: (component) => component.VideoGalleryPage,
      }),
    ),
  },
  {
    path: paths.news(),
    exact: true,
    component: withModel(
      newsPageModel,
      loadable(() => import('@pages/news'), {
        ssr: true,
        resolveComponent: (component) => component.NewsPage,
      }),
    ),
  },
  {
    path: paths.live(),
    exact: true,
    component: withModel(
      livePageModel,
      loadable(() => import('@pages/live'), {
        ssr: true,
        resolveComponent: (module) => module.LivePage,
      }),
    ),
  },
  {
    path: paths.newsById(),
    exact: true,
    component: withModel(
      singleNewsModel,
      loadable(() => import('@pages/news/single-news'), {
        ssr: true,
        resolveComponent: (component) => component.NewsOpenPage,
      }),
    ),
  },
  {
    path: paths.contacts(),
    exact: true,
    component: withModel(
      contactsPageModel,
      loadable(() => import('@pages/contacts'), {
        ssr: true,
        resolveComponent: (component) => component.ContactsPage,
      }),
    ),
  },
  {
    path: '*',
    component: Error404Page,
  },
]
