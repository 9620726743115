import { createEvent, createStore, guard, sample } from 'effector-root'
import { always, not } from 'ramda'
import { combineEvents } from 'patronum'
import { createStart } from '@lib/page-routing'
import { contactsRepository, IContactsDto, ISubscribeDto } from '@api/contacts'
import { metaTagsRepository } from '@api/meta-tags'
import * as aboutPageModel from '@pages/about/model'

export const pageLoaded = createStart()

export const subscribe = createEvent<ISubscribeDto>()

export const resetSubscribed = createEvent()

const dataFetched = combineEvents({ events: [contactsRepository.findFx.doneData] })

export const $contactsPageMetaTags = createStore('')

const $dataWasFetchedServerSide = createStore(false)

export const $contacts = createStore<IContactsDto | null>(null)

export const $subscribed = createStore<boolean>(false)

export const $subscribeIsPending = contactsRepository.subscribeFx.pending

$dataWasFetchedServerSide.on(dataFetched, always(true))

$contactsPageMetaTags.on(metaTagsRepository.findContactsPageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

$contacts.on(contactsRepository.findFx.doneData, (_, contacts) => contacts)

$subscribed.on(contactsRepository.subscribeFx.done, always(true)).on(resetSubscribed, always(false))

guard({
  source: $dataWasFetchedServerSide,
  clock: [pageLoaded, aboutPageModel.pageLoaded],
  filter: not,
  target: [contactsRepository.findFx, metaTagsRepository.findContactsPageMetaTagsFx],
})

sample({
  clock: subscribe,
  target: contactsRepository.subscribeFx,
})
