import React from 'react'
import styled from 'styled-components'
import { isClientSide } from '@lib/ssr'
import { useDisabledBodyScroll } from '@lib/use-disabled-body-scroll'

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  isOpened: boolean
  hideOverlay?: boolean
  onClose: () => void
}

export const Modal = React.memo((props: ModalProps) => {
  const [_, setBodyScrollIsDisabled] = useDisabledBodyScroll(false)

  React.useEffect(() => {
    if (props.isOpened && isClientSide()) {
      setBodyScrollIsDisabled(true)
      return
    }

    setBodyScrollIsDisabled(false)
  }, [props.isOpened])

  const ref = React.useRef<HTMLImageElement>(null)

  const onMouseDown = React.useCallback((e: MouseEvent) => {
    // @ts-ignore
    if ((ref.current !== null && !ref.current.contains(e.target)) || e.target === ref.current) {
      props.onClose()
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('mousedown', onMouseDown)

    return () => document.removeEventListener('mousedown', onMouseDown)
  }, [])

  if (!props.isOpened) {
    return null
  }

  return (
    <Overlay ref={ref} data-hidden={props.hideOverlay}>
      {props.children}
    </Overlay>
  )
})

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(1, 1, 1, 0.53);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000000;

  &[data-hidden='true'] {
    background-color: transparent;
  }
`
