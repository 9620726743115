import { createStore, guard } from 'effector-root'
import { always, not } from 'ramda'
import { createStart } from '@lib/page-routing'
import { metaTagsRepository } from '@api/meta-tags'

export const pageLoaded = createStart()

export const $photoVideoPageMetaTags = createStore('')

const $dataWasFetchedServerSide = createStore(false)

$dataWasFetchedServerSide.on(metaTagsRepository.findPhotoVideoPageMetaTagsFx.doneData, always(true))

$photoVideoPageMetaTags.on(metaTagsRepository.findPhotoVideoPageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: metaTagsRepository.findPhotoVideoPageMetaTagsFx,
})
