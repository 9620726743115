import { Effect, createEffect } from 'effector-root'
import { http } from '@api/http'

export interface IMetaTagsDto {
  id: number
  metaTags: string
}

export interface ILiveVideoRepository {
  findHomePageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findAboutPageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findMusicPageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findPhotoVideoPageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findNewsPageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findLivePageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
  findContactsPageMetaTagsFx: Effect<void, IMetaTagsDto, Error>
}

export const metaTagsRepository: ILiveVideoRepository = {
  findHomePageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/home-page-meta-tags').then((res) => res.data),
  ),
  findAboutPageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/about-page-meta-tags').then((res) => res.data),
  ),
  findMusicPageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/music-page-meta-tags').then((res) => res.data),
  ),
  findPhotoVideoPageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/photo-video-page-meta-tags').then((res) => res.data),
  ),
  findNewsPageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/news-page-meta-tags').then((res) => res.data),
  ),
  findLivePageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/live-page-meta-tags').then((res) => res.data),
  ),
  findContactsPageMetaTagsFx: createEffect(
    (): Promise<IMetaTagsDto> => http.get('/contacts-page-meta-tags').then((res) => res.data),
  ),
}
