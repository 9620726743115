import { createEffect, createStore } from 'effector-root'
import { CSSProperties } from 'react'
import { timeout } from '@lib/timeout'

export const coverUrlChanged = createEffect(async (src: string) => {
  await timeout(0)

  return src
})

export const coverStyleChanged = createEffect(async (style: CSSProperties) => {
  await timeout(0)

  return style
})

export const $coverUrl = createStore('')

export const $coverStyle = createStore<CSSProperties | null>(null)

$coverUrl.on(coverUrlChanged.doneData, (_, src) => src)

$coverStyle.on(coverStyleChanged.doneData, (_, style) => style)
