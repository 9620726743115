import { createEffect, Effect } from 'effector-root'
import { http } from '@api/http'
import { IImageMediaDto } from '@api/typings'

export interface ISlidersRepository {
  findAllFx: Effect<void, ISliderItemDto[], Error>
}

export enum SliderActions {
  None = 'none',
  Play = 'play',
  Tickets = 'tickets',
}

export enum LogoColors {
  White = 'white',
  Black = 'black',
}

export interface ISliderItemDto {
  cover: IImageMediaDto
  action: SliderActions
  logocolor: LogoColors
  created_at: string
  id: number
  published_at: string
  updated_at: string
}

export const sliderItemsRepository: ISlidersRepository = {
  findAllFx: createEffect((): Promise<ISliderItemDto[]> => http.get('/home-page-sliders').then((res) => res.data)),
}
