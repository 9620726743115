import { always, map, not } from 'ramda'
import { createStore, guard, sample } from 'effector-root'
import { combineEvents } from 'patronum'
import { createStart } from '@lib/page-routing'
import * as musicPlayer from '@features/music-player/model'
import { tracksRepository } from '@api/tracks'
import { metaTagsRepository } from '@api/meta-tags'

const toTracks = map(musicPlayer.toTrack)

export const pageLoaded = createStart()

const dataFetched = combineEvents({
  events: [tracksRepository.findAllFx.doneData, metaTagsRepository.findMusicPageMetaTagsFx.doneData],
})

export const $musicPageMetaTags = createStore('')

const $dataWasFetchedServerSide = createStore(false)

$dataWasFetchedServerSide.on(dataFetched, always(true))

$musicPageMetaTags.on(metaTagsRepository.findMusicPageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: [tracksRepository.findAllFx, metaTagsRepository.findMusicPageMetaTagsFx],
})

sample({ source: tracksRepository.findAllFx.doneData.map(toTracks), target: musicPlayer.$tracks })

// sample({
//   source: guard({
//     source: musicPlayer.$playingTrack,
//     filter: (track): track is ITrack => track !== null,
//   }),
//   target: pageCoverModel.coverUrlChanged,
//   fn: (track) => track.coverUrl,
// })
