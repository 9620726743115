import { createStore, guard, sample } from 'effector-root'
import { always, map, not } from 'ramda'
import format from 'date-fns/format'
import { createStart } from '@lib/page-routing'
import { INewsDto, newsRepository } from '@api/news'
import { EntityId } from '@typings'
import * as singleNewsModel from './single-news/model'
import { combineEvents } from 'patronum'
import { metaTagsRepository } from '@api/meta-tags'

interface News {
  id: EntityId
  preview: string
  date: string
  title: string
  description: string
  fullDescription: string
  content?: string
}

const toNewsConvert = (dto: INewsDto): News => ({
  id: dto.id,
  preview: dto.preview.url,
  date: format(new Date(dto.date), 'dd.MM.yyyy'),
  title: dto.title,
  description: dto.description,
  fullDescription: dto.fullDescription,
  content: dto.content?.url ?? '',
})

const toNewsConvertMap = map(toNewsConvert)

export const pageLoaded = createStart()

const dataFetched = combineEvents({
  events: [newsRepository.findAllFx.doneData, metaTagsRepository.findNewsPageMetaTagsFx.doneData],
})

const $dataWasFetchedServerSide = createStore(false)

export const $newsPageMetaTags = createStore('')

export const $newsList = createStore<News[]>([])

$dataWasFetchedServerSide.on(dataFetched, always(true))

$newsPageMetaTags.on(metaTagsRepository.findNewsPageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

guard({
  source: $dataWasFetchedServerSide,
  clock: [pageLoaded, singleNewsModel.pageLoaded],
  filter: not,
  target: newsRepository.findAllFx,
})

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: metaTagsRepository.findNewsPageMetaTagsFx,
})

sample({ source: newsRepository.findAllFx.doneData, target: $newsList, fn: toNewsConvertMap })
