import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react/ssr'
import NoSSR from 'react-no-ssr'
import { Devices } from '@styles'
import * as model from './model'
import { useLocation } from 'react-router'
import { paths } from '@pages/paths'
import * as musicPlayerModel from '@features/music-player/model'

const CoverWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`

const TrackCover = styled.div`
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  filter: blur(10px) brightness(50%);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and ${Devices.laptop} {
    display: block;
  }
`

const CoverImage = styled.img`
  display: none;

  width: 100%;
  height: 100%;
  z-index: 1;

  @media screen and ${Devices.laptop} {
    display: unset;
  }
`

interface CoverProps {
  src?: string
}

export const PageCover = (props: CoverProps) => {
  const src = useStore(model.$coverUrl)
  const isPlaying = useStore(musicPlayerModel.$trackModalIsOpened)
  const playingTrack = useStore(musicPlayerModel.$openedTrack)

  const location = useLocation()

  const showTrackCover = location.pathname === paths.music() && isPlaying

  return (
    <NoSSR>
      {showTrackCover ? (
        <TrackCover style={{ backgroundImage: `url(${playingTrack?.coverUrl})` }} />
      ) : (
        <CoverWrapper>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {src !== '' && <CoverImage {...props} src={src} />}
        </CoverWrapper>
      )}
    </NoSSR>
  )
}
