import React from 'react'
import { createEvent, createStore } from 'effector-root'
import { useEvent } from 'effector-react/ssr'
import { throttle } from 'patronum/throttle'
import { Devices } from '@styles'
import { isServerSide } from '@lib/ssr'

interface Size {
  width: number | undefined
  height: number | undefined
}

const deviceChanged = createEvent<Devices>()

const debouncedDeviceChanged = throttle({ source: deviceChanged, timeout: 10 })

export const $device = createStore<Devices>(getWindowSize())

export const $isMobile = $device.map((device) => [Devices.mobileS, Devices.mobileM, Devices.mobileL].includes(device))

$device.on(debouncedDeviceChanged, (_, device) => device)

export function getWindowSize(): Devices {
  if (isServerSide()) {
    return Devices.unknown
  }

  if (window.matchMedia(Devices.desktopL).matches) {
    return Devices.desktopL
  }

  if (window.matchMedia(Devices.desktopM).matches) {
    return Devices.desktopM
  }

  if (window.matchMedia(Devices.desktopS).matches) {
    return Devices.desktopS
  }

  if (window.matchMedia(Devices.tablet).matches) {
    return Devices.tablet
  }

  if (window.matchMedia(Devices.mobileL).matches) {
    return Devices.mobileL
  }

  if (window.matchMedia(Devices.mobileM).matches) {
    return Devices.mobileM
  }

  if (window.matchMedia(Devices.mobileS).matches) {
    return Devices.mobileS
  }

  return Devices.unknown
}

export const WindowSizeObserver = () => {
  // eslint-disable-next-line no-use-before-define
  const size = useWindowSize()
  const onDeviceChange = useEvent(deviceChanged)

  React.useEffect(() => {
    const device = getWindowSize()
    onDeviceChange(device)
  }, [onDeviceChange, size])

  return null
}

function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
  })

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}
