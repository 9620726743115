import { combine, createEffect, createEvent, createStore, guard, sample } from 'effector-root'
import { http } from '@api/http'
import { createStart } from '@lib/page-routing'

export const pageLoaded = createStart()

type Video = { id: number; url: string; title: string; description: string }

const getVideosFx = createEffect(
  (): Promise<Video[]> => {
    return http.get('/video-galleries').then((res) => res.data)
  },
)

export const selectVideo = createEvent<number | null>()

export const next = createEvent()

export const prev = createEvent()

export const resetSelectedVideo = createEvent()

export const $videos = createStore<Video[]>([])

const $selectedVideoIndex = createStore<number | null>(null)

export const $selectedVideo = combine([$videos, $selectedVideoIndex], ([videos, selectedVideoIndex]) => {
  if (selectedVideoIndex === null) {
    return null
  }

  const video = videos[selectedVideoIndex]

  return {
    id: video.id,
    title: video.title,
    description: video.description,
    type: 'video/youtube',
    src: video.url,
  }
})

$videos.on(getVideosFx.doneData, (_, videos) => videos).reset(resetSelectedVideo)

$selectedVideoIndex.on(selectVideo, (_, index) => index)

sample({
  clock: pageLoaded,
  target: getVideosFx,
})

// sample({
//   source: $isMobile,
//   clock: getVideosFx.doneData,
//   fn: (isMobile, videos) => ({ isMobile, videos }),
//   target: selectVideo.prepend(({ isMobile, videos }: { isMobile: boolean; videos: Video[] }) => {
//     if (isMobile) {
//       return null
//     }
//
//     const first = videos[0]
//
//     if (!first) {
//       return -1
//     }
//
//     return 0
//   }),
// })

guard({
  source: sample({
    source: { videos: $videos, selectedVideoIndex: $selectedVideoIndex },
    clock: next,
  }),
  filter: (params: {
    videos: Video[]
    selectedVideoIndex: number | null
  }): params is { videos: Video[]; selectedVideoIndex: number } => {
    if (params.selectedVideoIndex === null) {
      return false
    }

    return params.selectedVideoIndex + 1 <= params.videos.length - 1
  },
  target: selectVideo.prepend(({ selectedVideoIndex }: { selectedVideoIndex: number }) => selectedVideoIndex + 1),
})

guard({
  source: sample({
    source: { videos: $videos, selectedVideoIndex: $selectedVideoIndex },
    clock: prev,
  }),
  filter: (params: {
    videos: Video[]
    selectedVideoIndex: number | null
  }): params is { videos: Video[]; selectedVideoIndex: number } => {
    if (params.selectedVideoIndex === null) {
      return false
    }

    return params.selectedVideoIndex - 1 >= 0
  },
  target: selectVideo.prepend(({ selectedVideoIndex }: { selectedVideoIndex: number }) => selectedVideoIndex - 1),
})
