import React from 'react'
import { DownloadIcon } from '@ui/icons/download'
import { Circle } from '@ui/icons/circle'
import { ITrack } from '@features/music-player/model'
import styles from './expanded-mobile-player.module.scss'
import Close from './close.svg'
import Play from './play.svg'
import Pause from './pause.svg'
import Prev from './prev.svg'
import Next from './next.svg'

interface MobileModalContentProps {
  track: ITrack
  isPlaying: boolean
  playedPercents: number
  progressBarClicked: ({ progressPercents }: { progressPercents: number }) => void
  onClose: () => void
  onPlayClick: () => void
  onPauseClick: () => void
  onNextClick: () => void
  onPrevClick: () => void
}

export const ExpandedMobilePlayer = (props: MobileModalContentProps) => {
  const progressBarWrapperRef = React.useRef<HTMLDivElement>(null)
  const progressBarRef = React.useRef<HTMLDivElement>(null)
  const thumbRef = React.useRef<HTMLDivElement>(null)

  const progressBarWidth = `${(props.playedPercents / 100) * (window.innerWidth - 50)}px`

  const onProgressBarClick = (e: React.MouseEvent) => {
    if (progressBarWrapperRef.current !== null) {
      props.progressBarClicked({
        progressPercents: ((e.clientX - 25) / progressBarWrapperRef.current.clientWidth) * 100,
      })
    }
  }

  return (
    <div className={styles.background}>
      <div className={styles.topControl}>
        <div onClick={props.onClose}>
          <Close />
        </div>

        <a className={styles.downloadLink} href={props.track.downloadLink} target='_blank'>
          <DownloadIcon />
          <span>Скачать</span>
        </a>
      </div>

      <div className={styles.trackCoverWrapper}>
        <img src={props.track.coverUrl} alt='' />
      </div>

      <div className={styles.trackInformation}>
        <span>{props.track.year}</span>
        <Circle />
        <span>{props.track.artist}</span>
      </div>

      <span className={styles.trackTitle}>{props.track.title}</span>

      <div className={styles.progressIndicator} ref={progressBarWrapperRef} onClick={onProgressBarClick}>
        <div className={styles.progressBar} style={{ width: progressBarWidth }} ref={progressBarRef} />
        <div className={styles.thumb} style={{ left: progressBarWidth }} ref={thumbRef} />
      </div>

      <div className={styles.actions}>
        <div onClick={props.onPrevClick}>
          <Prev />
        </div>

        {props.isPlaying ? (
          <div onClick={props.onPauseClick}>
            <Pause />
          </div>
        ) : (
          <div onClick={props.onPlayClick}>
            <Play />
          </div>
        )}

        <div onClick={props.onNextClick}>
          <Next />
        </div>
      </div>
    </div>
  )
}
