import { createStore, guard } from 'effector-root'
import { always, not } from 'ramda'
import { createStart } from '@lib/page-routing'
import { metaTagsRepository } from '@api/meta-tags'

export const pageLoaded = createStart()

const $dataWasFetchedServerSide = createStore(false)

export const $aboutPageMetaTags = createStore('')

$dataWasFetchedServerSide.on(metaTagsRepository.findAboutPageMetaTagsFx, always(true))

$aboutPageMetaTags.on(metaTagsRepository.findAboutPageMetaTagsFx.doneData, (_, dto) => dto.metaTags)

guard({
  source: $dataWasFetchedServerSide,
  clock: pageLoaded,
  filter: not,
  target: metaTagsRepository.findAboutPageMetaTagsFx,
})
